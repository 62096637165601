import fetchAPI from '_lib/api/fetchWrapper';
import apiRoute from '_lib/api/paths';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { all, put, call } from 'typed-redux-saga';
import settingsReducer from './settingsReducers';
import { Method, SettingsPayload } from '_lib/api';
import { stringToBoolean } from '_store/utils';

export function* settingsRequest(_action: PayloadAction<SettingsPayload>) {
  try {
    const response = yield fetchAPI(apiRoute.getSettings, { method: Method.GET });
    const { denm } = response.distribution;
    const { cam } = response.storage;
    const convertedSettingsResponse = {
      distribution: {
        denm: {
          datexii: stringToBoolean(denm.datexii),
          emeras: stringToBoolean(denm.emeras),
          connex: stringToBoolean(denm.connex),
        },
      },
      storage: {
        cam: {
          obu: cam.obu,
          obuFilterEnabled: cam.obuFilterEnabled,
        },
      },
    };
    yield put(settingsReducer.actions.updateSettingsResponse(convertedSettingsResponse));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('ERROR settingsRequest >>>>>>> ', e);
  }
  return undefined;
}

export function* updateSettings(_action: PayloadAction<SettingsPayload>) {
  try {
    const {
      distribution: { denm: { source, value: denmValue } = { source: '', value: false } } = {},
      storage: { cam: { obu, obuFilterEnabled } = { obu: {}, obuFilterEnabled: false } } = {},
    } = _action.payload || {};

    const apiCalls = [
      {
        condition: _action.payload.distribution,
        route: apiRoute.updateSettings('distribution/denm', source),
        params: { value: denmValue },
      },
      {
        condition: _action.payload.storage?.cam?.hasOwnProperty('obuFilterEnabled'),
        route: apiRoute.updateSettings('storage/cam', 'obuFilterEnabled'),
        params: { value: obuFilterEnabled },
      },
      {
        condition: _action.payload.storage?.cam?.obu,
        route: apiRoute.updateSettings('storage/cam', 'obu'),
        params: obu,
      },
    ];

    const results = yield all(
      apiCalls
        .filter((call) => call.condition)
        .map((apiCall) =>
          call(fetchAPI, apiCall.route, {
            method: Method.POST,
            params: apiCall.params,
          }),
        ),
    );

    if (results.some((response) => response.result === 'OK')) {
      yield put(settingsReducer.actions.settingsRequest({}));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('ERROR updateSettings >>>>>>> ', e);
  }
  return undefined;
}

export const settingsSaga = createSliceSaga({
  name: 'settings',
  caseSagas: {
    settingsRequest,
    updateSettings,
  },
  sagaType: SagaType.Watch,
});
