import { Box, CardHeader } from '@material-ui/core';
import React from 'react';
import { FabButton } from '../../../../components/items/FabButton';
import styles from '../styles';

interface CardHeaderBoxProps {
  cardHeaderTitle: string;
  onFabClick?: () => void;
  onFabClickText?: string;
}

export const CardHeaderBox = ({
  cardHeaderTitle,
  onFabClick,
  onFabClickText,
}: CardHeaderBoxProps) => {
  const classes = styles();

  return (
    <Box style={{ display: 'flex', position: 'relative' }}>
      <CardHeader
        title={cardHeaderTitle}
        className={classes.header}
        titleTypographyProps={{ variant: 'h6' }}
      />
      {onFabClick && <FabButton onClick={onFabClick} buttonText={onFabClickText} />}
    </Box>
  );
};
