import { Map } from 'leaflet';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import { Ivim } from '../../../../../store/ivim/ivimsTypes';
import IvimMarker from './markers/IvimMarker';

const Ivims: FC<{ ivims: Ivim[]; mapRef: Map }> = ({ ivims, mapRef }): ReactElement => {
  // workaround to force re-render of markers https://github.com/yuzhva/react-leaflet-markercluster/issues/149
  const [renderKey, setRenderKey] = useState(0);
  useEffect(() => {
    setRenderKey((k) => k + 1);
  }, [ivims]);

  return (
    <MarkerClusterGroup maxClusterRadius={160} disableClusteringAtZoom={13} key={renderKey}>
      {ivims.map((ivim, key) => (
        <IvimMarker key={key} ivim={ivim} mapRef={mapRef} />
      ))}
    </MarkerClusterGroup>
  );
};

export default React.memo(Ivims);
