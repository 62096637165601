import { makeStyles } from '@material-ui/core';

const styles = makeStyles((_theme) => ({
  main: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: 5,
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: 'left',
  },
}));

export default styles;
