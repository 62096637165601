import { PayloadAction } from '@reduxjs/toolkit';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { all, call, put } from 'typed-redux-saga';

import applicationReducer from '../../application/reducer';
import denmsReducer from '../../denm/denmsReducers';
import devicesReducer from '../../devices/devicesReducers';
import ivimsReducer from '../../ivim/ivimsReducers';
import { roadsRequest } from '../../roads/sagas';
import {
  pollTaskWatcherCAM,
  pollTaskWatcherDENM,
  pollTaskWatcherIVIM,
  pollTaskWatcherRSU,
} from './webSocket';
import roadsReducer from '_store/roads/roadsReducers';
import { getFromLocalStorage } from '../../../utils';
import { DenmSourceFilter, DenmStatusFilter } from '_store/denm/denmsTypes';
import { IvimStatusFilter } from '_store/ivim/ivimsTypes';
import settingsReducer from '_store/settings/settingsReducers';

function* createClientFromCredentials(_action: PayloadAction<{ token: string }>) {
  const { token } = _action.payload;
  window.sessionStorage.setItem('token', token);
  const roadsResponse = yield call(roadsRequest, null);
  const selectedRoadsFromLocalStorage = yield call(getFromLocalStorage, 'selectedRoadIds');
  const roadSegmentIds: string[] = selectedRoadsFromLocalStorage
    ? JSON.parse(selectedRoadsFromLocalStorage)
    : [];
  const roadSegments =
    roadSegmentIds && roadSegmentIds.length > 0
      ? roadSegmentIds
      : roadsResponse?.results.map((road) => road.id);

  try {
    yield put(settingsReducer.actions.settingsRequest({}));
    yield all([
      put(denmsReducer.actions.causesRequest()),
      put(denmsReducer.actions.subCausesRequest()),
    ]);
    if (roadSegments) {
      yield put(devicesReducer.actions.devicesRequest(roadSegments));
      yield put(
        denmsReducer.actions.denmsRequest({
          status: DenmStatusFilter.IN_PROGRESS,
          roadSegmentIds: roadSegments,
          sources: [DenmSourceFilter.EMERAS],
        }),
      );
      yield put(
        ivimsReducer.actions.ivimsRequest({
          roadSegmentIds: roadSegments,
          status: IvimStatusFilter.IN_PROGRESS,
        }),
      );
      yield put(roadsReducer.actions.selectRoad(roadSegments));
    }
    yield put(applicationReducer.actions.readyStatusReached());
    yield put(applicationReducer.actions.setUserLoggedIn());
    yield all([
      call(pollTaskWatcherDENM, token),
      call(pollTaskWatcherIVIM, token),
      call(pollTaskWatcherRSU, token),
      call(pollTaskWatcherCAM, token),
    ]);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('createClientFromCredentials ERROR >>>>>>>>>>> ', e);
  }
}

function* renewToken(_action: PayloadAction<{ token: string }>) {
  const { token } = _action.payload;
  window.sessionStorage.setItem('token', token);
  try {
    yield all([
      call(pollTaskWatcherDENM, token),
      call(pollTaskWatcherIVIM, token),
      call(pollTaskWatcherRSU, token),
      call(pollTaskWatcherCAM, token),
    ]);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('renewal token ERROR >>>>>>>>>>> ', e);
  }
}

export const apiSagas = createSliceSaga({
  name: 'api',
  caseSagas: {
    createClientFromCredentials,
    renewToken,
  },
  sagaType: SagaType.Watch,
});

export default apiSagas;
