import { makeStyles } from '@material-ui/core';

const styles = makeStyles((_theme) => ({
  cardStyle: {
    maxWidth: '40vh',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0vh',
    marginBottom: '1.5vh',
    flex: 1,
    marginRight: '1.5vh',
    height: 'calc(100vh - 92px)',
    overflow: 'auto',
  },
  header: {
    textAlign: 'left',
    fontSize: 15,
    alignItems: 'left',
    marginTop: 5,
    paddingTop: 0,
    paddingBottom: 0,
  },
  close: {
    width: 35,
    height: 35,
    alignSelf: 'flex-end',
    marginTop: 10,
    marginRight: 10,
  },
}));

export default styles;
