import { RSU } from '_lib/api';
import { RootState } from '_store';
import applicationReducer from '_store/application/reducer';
import { dialogSelector, selectionSelector } from '_store/application/selectors';
import { DialogType } from '_store/application/types';
import devicesReducer from '_store/devices/devicesReducers';
import { RsuStatusCode } from '_store/devices/devicesTypes';
import L, { Map } from 'leaflet';
import React, { FC, ReactElement, useMemo } from 'react';
import { Marker } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';

import { canInfoViewBeOpened } from '../../../InfoView/utils';
import RsuInfoTooltip from '../../InfoTooltips/RsuInfoTooltip';
import { HoveringElementType } from '../../InfoTooltips/types';
import { iconSizePx, rsuStatusIcon, toLatLng } from '../../utils';

const iconSize = new L.Point(iconSizePx, iconSizePx);
const iconAnchor = new L.Point(iconSize.x / 2, iconSize.y);
const tooltipOffset = new L.Point(0, -iconSize.y);

const RsuMarker: FC<{
  device: RSU;
  mapRef: Map;
}> = ({ device, mapRef }): ReactElement => {
  const dispatch = useDispatch();

  const dialog = useSelector(dialogSelector);
  const selection = useSelector(selectionSelector);
  const canOpenInfo = canInfoViewBeOpened(dialog, selection);

  const diagnostic = useSelector((state: RootState) => state.api.diagnostic[device.id]);
  let iconStatus: RsuStatusCode;
  if (diagnostic) {
    const hdd = diagnostic.statusDetails?.diskStatus;
    const udp = diagnostic.service;
    if (hdd) {
      if (udp) {
        iconStatus = RsuStatusCode.UP;
      } else {
        iconStatus = RsuStatusCode.ERROR;
      }
    } else {
      iconStatus = RsuStatusCode.WARNING;
    }
  }
  if (device.alive) {
    iconStatus = RsuStatusCode.UP;
  }

  const markerPosition = useMemo(() => toLatLng(device?.location?.coordinates), [device]);

  const eventHandlers = useMemo(
    () => ({
      click: () => {
        if (canOpenInfo) {
          dispatch(devicesReducer.actions.deviceRequest(device.id));
          dispatch(
            applicationReducer.actions.infoSelected({
              type: DialogType.SINGLE_DEVICE,
              id: device.id,
            }),
          );
          mapRef.panTo(markerPosition);
        }
      },
    }),
    [mapRef, device, markerPosition, dialog, selection],
  );

  const isRsuSelected =
    dialog &&
    [DialogType.SINGLE_DEVICE, DialogType.DEVICE_PATH].includes(dialog.type) &&
    dialog.id === device.id;

  return (
    <div>
      <Marker
        position={markerPosition}
        icon={
          new L.Icon({
            iconSize,
            iconUrl: rsuStatusIcon(iconStatus),
            iconAnchor,
            className: `marker-event-${isRsuSelected ? 'selected' : 'unselected'}`,
          })
        }
        interactive={true}
        eventHandlers={eventHandlers}
      >
        <RsuInfoTooltip
          hoveringElement={{
            type: HoveringElementType.RSU,
            device,
            offset: tooltipOffset,
          }}
        />
      </Marker>
    </div>
  );
};

export default React.memo(RsuMarker);
