import { RSU } from '_lib/api';
import { Map } from 'leaflet';
import React, { FC, ReactElement } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { v4 as uuidv4 } from 'uuid';

import RsuMarker from './markers/RsuMarker';

const Devices: FC<{ devices: RSU[]; mapRef: Map }> = ({ devices, mapRef }): ReactElement => {
  const uniqueKey = uuidv4();
  return (
    <MarkerClusterGroup
      key={`${uniqueKey}_devices`}
      maxClusterRadius={160}
      disableClusteringAtZoom={13}
      // iconCreateFunction={(cluster) => {
      //   const markers = cluster.getAllChildMarkers();
      //   const html = `<div class="circle">${markers.length}</div>`;
      //   return L.divIcon({ html, className: 'mycluster', iconSize: L.point(32, 32) });
      // }}
    >
      {devices.map((device, key) => (
        <RsuMarker key={`${uniqueKey}${key}`} device={device} mapRef={mapRef} />
      ))}
    </MarkerClusterGroup>
  );
};

export default React.memo(Devices);
