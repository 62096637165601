import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  fab: {
    right: '10px',
    top: '-35px',
    position: 'absolute',
    backgroundColor: '#005abb',
  },
  fabText: {
    textTransform: 'none',
    fontWeight: 'normal',
  },
  fabIcon: {
    color: '#fff',
    marginRight: theme.spacing(1),
  },
}));

export default styles;
