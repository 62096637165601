import { useLocalization } from '@fluent/react';
import { Box, Button, CardContent, Divider, TextField, Tooltip } from '@material-ui/core';
import { Coordinate, CreateDENMPayload } from '_lib/api';
import { RootState, useAppDispatch } from '_store';
import appReducer from '_store/application/reducer';
import {
  MapElementType,
  PointCount,
  PointSelectionParams,
  PointSelectionType,
} from '_store/application/types';
import denmsReducer from '_store/denm/denmsReducers';
import {
  causesSelector,
  denmCauseRoadWorksSelector,
  denmsSelector,
  subCausesByCauseSelector,
  subCausesSelector,
} from '_store/denm/selectors';
import denmFormReducer, { CreateDENMForm } from '_store/forms/manage-denm';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { extraSelector, extraTraceMapElementsSelector } from '_store/application/selectors';
import { Denm } from '_store/denm/denmsTypes';
import {
  checkValidityPeriod,
  convertFromMilliseconds,
  convertToMilliseconds,
  useDenmsOptionalCauses,
} from '_store/denm/utils';
import { roadsSelector, selectedRoadSegmentIdsSelector } from '_store/roads/selectors';
import ButtonGroup from '../../../items/ButtonGroup';
import FreeformSelect from '../../../items/FreeformSelect';
import SelectItem from '../../../items/SelectItem';
import { DenmColors, equidistantSegments, pointStyles } from '../../../routes/MapView/utils';
import { DenmTypeEnum } from '../types';
import { MainTrace, MainTraceType } from './components/MainTrace';
import { DenmOptionals } from './components/Optionals';
import { Trace } from './components/Trace';
import { TracesList } from './components/TracesList';
import styles from './styles';
import { Waypoints } from '_store/roads/roadsTypes';

// TODO Replace this with a proper validation library (when we have more time)
export function isFormValid(form: CreateDENMForm): boolean {
  return (
    form.cause !== null &&
    form.subcause !== undefined &&
    form.referencePoint !== null &&
    form.tracezoneCoordinates &&
    form.frequencybroadcasting !== null &&
    !Number.isNaN(form.frequencybroadcasting) &&
    form.validityperiod !== null &&
    !Number.isNaN(form.validityperiod)
  );
}

interface DenmManageProps {
  id: string;
  type: DenmTypeEnum;
  closeDialog: () => void;
}

const DenmManage = ({ id, type, closeDialog }: DenmManageProps) => {
  const { l10n } = useLocalization();
  const classes = styles();
  const dispatch = useAppDispatch();
  const formState = useSelector((state: RootState) => state.forms['manage-denm']);
  const pointSelection = useSelector((state: RootState) => state.application.pointSelections);
  const { active: psActive, points } = pointSelection;
  const currentSelection = useSelector((state: RootState) => state.application.currentSelection);
  const selectedRoadSegmentIds: string[] = useSelector(selectedRoadSegmentIdsSelector);

  const denmCauses = useSelector(causesSelector);
  const denmSubCauses = useSelector(subCausesSelector);
  const denmEvents = useSelector(subCausesByCauseSelector);
  const roadWorksId = useSelector(denmCauseRoadWorksSelector);
  const denms = useSelector(denmsSelector);
  const roads = useSelector(roadsSelector);

  const denm = denms.find((dev) => dev.id === id);
  useDenmsOptionalCauses({
    optionalCauseCode: denm.optional?.situation?.linkedCause?.causeCode,
    optionalSubCauseCode: denm.optional?.situation?.linkedCause?.subCauseCode,
  });

  const { busy } = useSelector((state: RootState) => state.application.activeDialog);
  const referencePointSelected =
    formState.referencePoint !== null && formState.referencePoint !== undefined;

  const extraMapElements = useSelector(extraSelector);
  const extraTraceMapElements = useSelector(extraTraceMapElementsSelector);

  const [mainTrace, setMainTrace] = useState<Coordinate[]>([]);
  const [selectedTraceIndex, setSelectedTraceIndex] = useState<number>(null);
  const [selectedTrace, setSelectedTrace] = useState<string>(null);

  const initialValidityPeriodRef = useRef(formState.validityperiod);
  const initialPeriodUnitRef = useRef(formState.periodUnit);

  const [clone] = useState(type === DenmTypeEnum.CLONE_DENM);

  const calculateValidityPeriod = (denm: Denm, isClone: boolean) => {
    let validityPeriod: number, periodUnit: string;

    if (isClone) {
      ({ validityPeriod, periodUnit } = convertFromMilliseconds(denm.validityPeriod));
    } else {
      const nowMillis = new Date().getTime();
      const expirationDate = new Date(denm.expiresAt);
      const expirationDateMillis = expirationDate.getTime();
      const differenceMillis = expirationDateMillis - nowMillis;
      ({ validityPeriod, periodUnit } = convertFromMilliseconds(differenceMillis));
    }

    initialValidityPeriodRef.current = validityPeriod;
    initialPeriodUnitRef.current = periodUnit;
    dispatch(denmFormReducer.actions.validityPeriodChanged(validityPeriod));
    dispatch(denmFormReducer.actions.periodUnitChanged(periodUnit));
  };

  const showTracesOnMap = useCallback(
    (
      params: PointSelectionParams,
      coordinates: Coordinate[],
      index?: number,
      mainTrace?: boolean,
    ) => {
      switch (params.type) {
        case PointSelectionType.DENM_REFERENCE_POINT:
          dispatch(appReducer.actions.removeAllMapElements());
          dispatch(denmFormReducer.actions.referencePointChanged(coordinates[0]));
          dispatch(denmFormReducer.actions.tracezoneRemoveAll());
          setMainTrace([]);
          break;
        case PointSelectionType.DENM_HISTORY:
          dispatch(denmFormReducer.actions.historyzoneChanged(coordinates));
          break;
        case PointSelectionType.DENM_TRACES:
          if (!mainTrace) {
            dispatch(denmFormReducer.actions.tracezoneChanged(coordinates));
          }
          break;
        default:
          break;
      }
      const elements = coordinates.map((loc) => ({
        ...pointStyles[params.type],
        options: {
          ...pointStyles[params.type].options,
          color: mainTrace ? DenmColors.MAIN_TRACE : pointStyles[params.type].options.color,
        },
        loc,
      }));

      if (coordinates.length > 1) {
        elements.push({
          type: MapElementType.POLYLINE,
          loc: coordinates[0],
          points: coordinates.map((p, _i, arr) => [p[0] - arr[0][0], p[1] - arr[0][1]]),
          options: {
            color: mainTrace ? DenmColors.MAIN_TRACE : pointStyles[params.type].options.color,
          },
        });
      }

      let paramsId =
        params.type === PointSelectionType.DENM_TRACES
          ? PointSelectionType.DENM_TRACES + (mainTrace ? 'main' : index)
          : params.type;

      dispatch(
        appReducer.actions.setMapElement({
          id: paramsId,
          elements,
        }),
      );

      if (params.type === PointSelectionType.DENM_TRACES && !mainTrace) {
        dispatch(appReducer.actions.setTraceMapElement({ id: paramsId, elements: coordinates }));
      }

      // Stop recording coordinates
      dispatch(appReducer.actions.pointSelectionAborted());
    },
    [dispatch, pointStyles],
  );

  useEffect(() => {
    if (id) {
      const denm = denms.find((dev) => dev.id === id);
      if (denm) {
        dispatch(appReducer.actions.removeTraceMapElements());

        const subCause = denmSubCauses.find((sc) => sc.id === denm?.subCauseId);
        const cause = subCause && denmCauses.find((c) => c.id === subCause.causeId);
        dispatch(denmFormReducer.actions.prefillForm(denm));
        updateCause({ target: { value: cause.id } });
        updateSubCause({ target: { value: subCause.id } });

        calculateValidityPeriod(denm, clone);

        // Show reference point on map
        if (denm.referencePoint.coordinates) {
          const referencePoints = [denm.referencePoint.coordinates];
          dispatch(denmFormReducer.actions.referencePointChanged(referencePoints[0]));
          const params: PointSelectionParams = {
            type: PointSelectionType.DENM_REFERENCE_POINT,
            pointCount: PointCount.ONE,
            style: pointStyles[PointSelectionType.DENM_REFERENCE_POINT],
          };
          showTracesOnMap(params, referencePoints);
        }

        // Show tracezoneCoordinates on map
        if (denm.traceZoneCoordinates.coordinates?.length > 0) {
          const params: PointSelectionParams = {
            type: PointSelectionType.DENM_TRACES,
            pointCount: PointCount.MANY,
            style: pointStyles[PointSelectionType.DENM_TRACES],
          };

          // main Trace
          const mainTraceCoordinates = denm.traceZoneCoordinates.coordinates[0];
          showTracesOnMap(params, mainTraceCoordinates, -1, true);

          setMainTrace(mainTraceCoordinates);

          denm.traceZoneCoordinates.coordinates.slice(1).forEach((coordinates, index) => {
            showTracesOnMap(params, coordinates, index);
          });
        }

        // Show historyZoneCoordinates on map
        if (denm.historyZoneCoordinates?.coordinates?.length > 0) {
          const params: PointSelectionParams = {
            type: PointSelectionType.DENM_HISTORY,
            pointCount: PointCount.MANY,
            style: pointStyles[PointSelectionType.DENM_HISTORY],
          };

          showTracesOnMap(params, denm.historyZoneCoordinates.coordinates);
        }
      }
    }
  }, [id]);

  useEffect(() => {
    dispatch(denmsReducer.actions.causesRequest());

    // TODO Restore points if returning to a prefilled form

    return () => {
      // Cleanup custom elements
      dispatch(appReducer.actions.removeMapElements(PointSelectionType.DENM_REFERENCE_POINT));
      dispatch(appReducer.actions.removeMapElements(PointSelectionType.DENM_HISTORY));
      dispatch(appReducer.actions.removeMapElements(PointSelectionType.DENM_TRACES));
      dispatch(appReducer.actions.removeTraceMapElements());
    };
  }, []);

  const isActiveSelection = useMemo(
    () => ({
      [PointSelectionType.DENM_REFERENCE_POINT]:
        psActive && currentSelection === PointSelectionType.DENM_REFERENCE_POINT,
      [PointSelectionType.DENM_HISTORY]:
        psActive && currentSelection === PointSelectionType.DENM_HISTORY,
      [PointSelectionType.DENM_TRACES]:
        psActive && currentSelection === PointSelectionType.DENM_TRACES,
    }),
    [psActive, currentSelection],
  );

  const isSelectionButtonDisabled = useMemo(
    () => ({
      [PointSelectionType.DENM_REFERENCE_POINT]:
        (psActive && !isActiveSelection[PointSelectionType.DENM_REFERENCE_POINT]) || busy,
      [PointSelectionType.DENM_HISTORY]:
        !referencePointSelected ||
        (psActive && !isActiveSelection[PointSelectionType.DENM_HISTORY]) ||
        busy,
      [PointSelectionType.DENM_TRACES]:
        !referencePointSelected ||
        (psActive && !isActiveSelection[PointSelectionType.DENM_TRACES]) ||
        busy,
    }),
    [
      referencePointSelected,
      psActive,
      isActiveSelection,
      busy,
      formState.tracezoneCoordinates,
      mainTrace,
    ],
  );

  const isTracesZoneDisabled = useMemo(
    () =>
      isSelectionButtonDisabled[PointSelectionType.DENM_TRACES] ||
      (formState.tracezoneCoordinates && formState.tracezoneCoordinates.length >= 6),
    [isSelectionButtonDisabled, formState],
  );

  const startSelection = useCallback(
    (params: PointSelectionParams) => {
      if (params.type !== PointSelectionType.DENM_TRACES) {
        dispatch(appReducer.actions.removeMapElements(params.type));
      }
      dispatch(appReducer.actions.pointSelectionStarted(params));
    },
    [dispatch, extraMapElements],
  );

  type CallbackType = (key: string) => void;

  const processArray = (
    index: number,
    formState: any,
    extraTraceMapElements: any,
    extraMapElements: any,
    callback: CallbackType,
  ) => {
    const targetArray = formState.tracezoneCoordinates[index];
    const matchingKey = Object.keys(extraTraceMapElements).find((key) =>
      isEqual(extraTraceMapElements[key], targetArray),
    );

    const correspondingIndex = matchingKey ? extraMapElements[matchingKey] : undefined;
    if (correspondingIndex !== undefined) {
      callback(matchingKey);
    }
  };

  const deleteTrace = useCallback(
    (index: number) => {
      processArray(index, formState, extraTraceMapElements, extraMapElements, (matchingKey) => {
        dispatch(appReducer.actions.removeMapElements(matchingKey));
      });

      dispatch(denmFormReducer.actions.tracezoneRemoved(index));
      if (selectedTraceIndex === index) {
        setSelectedTrace(null);
        setSelectedTraceIndex(null);
      }
    },
    [dispatch, formState.tracezoneCoordinates, extraTraceMapElements, extraMapElements],
  );

  const deleteMainTrace = useCallback(() => {
    dispatch(appReducer.actions.removeMapElements(PointSelectionType.DENM_TRACES + 'main'));
    setMainTrace([]);
    setSelectedTraceIndex(null);
  }, [dispatch, formState.tracezoneCoordinates, extraTraceMapElements, extraMapElements]);

  const endSelection = useCallback(
    (params: PointSelectionParams) => {
      // Take existing points
      switch (params.type) {
        case PointSelectionType.DENM_REFERENCE_POINT:
          dispatch(appReducer.actions.removeAllMapElements());
          dispatch(denmFormReducer.actions.referencePointChanged(points[0]));
          dispatch(denmFormReducer.actions.tracezoneRemoveAll());
          setMainTrace([]);
          break;
        case PointSelectionType.DENM_HISTORY:
          dispatch(denmFormReducer.actions.historyzoneChanged(points));
          break;
        case PointSelectionType.DENM_TRACES:
          dispatch(denmFormReducer.actions.tracezoneChanged(points));
          break;
        default:
          break;
      }
      // Show them on map
      const elements = points.map((loc) => ({
        ...pointStyles[params.type],
        loc,
      }));

      // If multiple points have been selected, draw a line between them
      if (points.length > 1) {
        elements.push({
          type: MapElementType.POLYLINE,
          loc: points[0],
          points: points.map((p, _i, arr) => [p[0] - arr[0][0], p[1] - arr[0][1]]),
          options: {
            color: pointStyles[params.type].options.color,
          },
        });
      }

      let paramsId =
        params.type === PointSelectionType.DENM_TRACES
          ? PointSelectionType.DENM_TRACES + Object.keys(extraTraceMapElements).length
          : params.type;

      dispatch(
        appReducer.actions.setMapElement({
          id: paramsId,
          elements,
        }),
      );

      if (params.type === PointSelectionType.DENM_TRACES) {
        dispatch(appReducer.actions.setTraceMapElement({ id: paramsId, elements: points }));
      }

      // Stop recording coordinates
      dispatch(appReducer.actions.pointSelectionAborted());
    },
    [dispatch, points, pointStyles],
  );

  const endSelectionMainTrace = useCallback(
    (type: MainTraceType) => {
      let mainTracePoints = [];
      if (type === MainTraceType.AUTOMATIC) {
        const roadSegment = formState.roadSegmentId ?? selectedRoadSegmentIds[0];
        const firstSelectedRoadSegment: Waypoints = (roads.find((r) => r.id === roadSegment)
          ?.waypoints || []) as Waypoints;
        if (Array.isArray(firstSelectedRoadSegment) && firstSelectedRoadSegment.length === 0) {
          return;
        }
        const es = equidistantSegments(firstSelectedRoadSegment, points[0], points[1]);
        const coordinates: Coordinate[] = es.geometry.coordinates.map((position) => [
          position[0],
          position[1],
        ]);
        dispatch(appReducer.actions.changePointCount(PointCount.MANY));
        coordinates.map((loc) => dispatch(appReducer.actions.pointSelected(loc)));
        mainTracePoints = coordinates;
      } else {
        mainTracePoints = points;
      }

      // Show them on map
      const elements = mainTracePoints.map((loc) => ({
        ...pointStyles[PointSelectionType.DENM_TRACES],
        options: {
          ...pointStyles[PointSelectionType.DENM_TRACES].options,
          color: DenmColors.MAIN_TRACE,
        },
        loc,
      }));

      // If multiple points have been selected, draw a line between them
      if (mainTracePoints.length > 1) {
        elements.push({
          type: MapElementType.POLYLINE,
          loc: mainTracePoints[0],
          points: mainTracePoints.map((p, _i, arr) => [p[0] - arr[0][0], p[1] - arr[0][1]]),
          options: {
            weight: 4,
            color: DenmColors.MAIN_TRACE,
          },
        });
      }

      let paramsId = PointSelectionType.DENM_TRACES + 'main';

      setMainTrace(mainTracePoints);
      dispatch(
        appReducer.actions.setMapElement({
          id: paramsId,
          elements,
        }),
      );
      dispatch(appReducer.actions.setTraceMapElement({ id: paramsId, elements: mainTracePoints }));
      dispatch(appReducer.actions.pointSelectionAborted());
    },
    [dispatch, points, pointStyles, formState.roadSegmentId, selectedRoadSegmentIds],
  );

  const toggleSelection = useCallback(
    (type: PointSelectionType) => {
      const params: PointSelectionParams = {
        type,
        pointCount:
          type === PointSelectionType.DENM_REFERENCE_POINT ? PointCount.ONE : PointCount.MANY,
        style: pointStyles[type],
      };

      if (isActiveSelection[type]) {
        endSelection(params);
      } else {
        startSelection(params);
      }
    },
    [pointStyles, isActiveSelection, endSelection, startSelection],
  );

  const submitForm = () => {
    const updatedTracezoneCoordinates = [mainTrace, ...formState.tracezoneCoordinates];
    const traceZoneCoordinates = {
      type: 'MultiLineString',
      coordinates: updatedTracezoneCoordinates,
    };
    const hzc = formState.historyzoneCoordinates;
    const historyZoneCoordinates =
      hzc && hzc.length > 1
        ? {
            type: 'LineString',
            coordinates: hzc,
          }
        : null;
    const referencePoint = {
      type: 'Point',
      coordinates: formState.referencePoint,
    };

    const validtyPeriodInMs = convertToMilliseconds({
      [formState.periodUnit]: formState.validityperiod,
    });

    const newFormState: CreateDENMPayload = {
      traceZoneCoordinates,
      ...(historyZoneCoordinates && { historyZoneCoordinates }),
      referencePoint,
      broadcastingFrequency: formState.frequencybroadcasting,
      subCauseId: formState.subcause,
      ...((clone ||
        formState.validityperiod !== initialValidityPeriodRef.current ||
        formState.periodUnit !== initialPeriodUnitRef.current) && {
        validityPeriod: validtyPeriodInMs,
      }),
      roadSegmentId: formState.roadSegmentId,
      optional: formState.optional,
    };
    clone
      ? dispatch(denmsReducer.actions.createDENM(newFormState))
      : dispatch(
          denmsReducer.actions.updateDENM({
            id,
            denmPayload: newFormState,
          }),
        );
    dispatch(denmFormReducer.actions.resetForm());
    closeDialog();
  };

  const causes = denmCauses.map(({ id, description }) => ({
    value: id,
    label: description,
  }));

  const subcauses = useMemo(
    () =>
      formState.cause === null
        ? []
        : denmEvents.map(({ id, description }) => ({
            value: id,
            label: description,
          })),
    [formState, denmEvents],
  );

  const updateCause = useCallback(
    (e) => {
      const id = e.target.value;
      dispatch(denmFormReducer.actions.subcauseChanged());
      dispatch(denmsReducer.actions.subCausesByCauseRequest(id));
      dispatch(denmFormReducer.actions.causeChanged(id));
    },
    [dispatch],
  );

  const updateSubCause = useCallback(
    (e) => {
      const id = e.target.value;
      dispatch(denmFormReducer.actions.subcauseChanged(id));
    },
    [dispatch],
  );

  const toggleSelectionReference = useCallback(() => {
    toggleSelection(PointSelectionType.DENM_REFERENCE_POINT);
  }, [toggleSelection]);
  const toggleSelectionHistory = useCallback(() => {
    toggleSelection(PointSelectionType.DENM_HISTORY);
  }, [toggleSelection]);
  const toggleSelectionTraces = useCallback(() => {
    toggleSelection(PointSelectionType.DENM_TRACES);
  }, [toggleSelection]);

  const toggleSelectionMainTrace = useCallback(
    (type: MainTraceType) => {
      const params: PointSelectionParams = {
        type: PointSelectionType.DENM_TRACES,
        pointCount: type === MainTraceType.MANUAL ? PointCount.MANY : PointCount.TWO,
        style: {
          ...pointStyles[PointSelectionType.DENM_TRACES],
          options: {
            ...pointStyles[PointSelectionType.DENM_TRACES].options,
            color: DenmColors.MAIN_TRACE,
          },
        },
      };

      if (isActiveSelection[PointSelectionType.DENM_TRACES]) {
        endSelectionMainTrace(type);
      } else {
        startSelection(params);
      }
    },
    [toggleSelection],
  );

  const updateValidityPeriod = useCallback(
    (e) => {
      const validityPeriod = checkValidityPeriod(parseInt(e.target.value), formState.periodUnit);
      dispatch(denmFormReducer.actions.validityPeriodChanged(validityPeriod));
    },
    [dispatch, formState.periodUnit],
  );
  const updateFrequencyBroadcasting = useCallback(
    (_e, value) => {
      dispatch(denmFormReducer.actions.frequencyBroadcastingChanged(parseFloat(value)));
    },
    [dispatch],
  );
  const updatePeriodUnit = useCallback(
    (e) => {
      const validityPeriod = checkValidityPeriod(formState.validityperiod, e.target.value);
      dispatch(denmFormReducer.actions.validityPeriodChanged(validityPeriod));
      dispatch(denmFormReducer.actions.periodUnitChanged(e.target.value));
    },
    [dispatch, formState.validityperiod],
  );

  const changeStyle = useCallback(
    (matchingKey: string, color: DenmColors, weight: number, className: string) => {
      dispatch(
        appReducer.actions.changeMapElementStyle({
          id: matchingKey,
          color,
          weight,
          className,
        }),
      );
    },
    [dispatch],
  );

  const highlightTrace = useCallback(
    (index: number) => {
      processArray(index, formState, extraTraceMapElements, extraMapElements, (matchingKey) => {
        processArray(
          selectedTraceIndex,
          formState,
          extraTraceMapElements,
          extraMapElements,
          (matchingKey) => {
            changeStyle(matchingKey, DenmColors.TRACE, 3, null);
          },
        );
        if (selectedTraceIndex !== index) {
          changeStyle(matchingKey, DenmColors.TRACE_SELECTED, 3, 'stroke-polyline');
          setSelectedTrace(matchingKey);
          setSelectedTraceIndex(index);
        } else {
          setSelectedTrace(null);
          setSelectedTraceIndex(null);
        }
        changeStyle(PointSelectionType.DENM_TRACES + 'main', DenmColors.MAIN_TRACE, 3, null);
      });
      // mainTrace
      if (index === -1) {
        if (selectedTraceIndex !== -1) {
          processArray(
            selectedTraceIndex,
            formState,
            extraTraceMapElements,
            extraMapElements,
            (matchingKey) => {
              changeStyle(matchingKey, DenmColors.TRACE, 3, null);
            },
          );

          setSelectedTrace(null);
          setSelectedTraceIndex(-1);
          changeStyle(
            PointSelectionType.DENM_TRACES + 'main',
            DenmColors.TRACE_SELECTED,
            3,
            'stroke-polyline',
          );
        } else {
          changeStyle(PointSelectionType.DENM_TRACES + 'main', DenmColors.MAIN_TRACE, 3, null);
          setSelectedTrace(null);
          setSelectedTraceIndex(null);
        }
      }
    },
    [
      formState.tracezoneCoordinates,
      dispatch,
      selectedTrace,
      selectedTraceIndex,
      extraTraceMapElements,
      extraMapElements,
      changeStyle,
    ],
  );
  return (
    <CardContent className={classes.cardContent}>
      <SelectItem
        text={l10n.getString('forms-denm-cause')}
        value={formState.cause ?? ''}
        options={causes}
        disabled={busy}
        onChange={updateCause}
        tooltip={l10n.getString('forms-denm-cs-tp')}
      />

      {subcauses?.length > 0 && (
        <SelectItem
          text={l10n.getString('forms-denm-subcause')}
          value={formState.subcause ?? ''}
          options={subcauses}
          disabled={formState.cause === null || busy}
          onChange={updateSubCause}
          tooltip={l10n.getString('forms-denm-sb-cs-tp')}
        />
      )}

      <ButtonGroup
        text={l10n.getString('forms-denm-reference-point')}
        buttons={[
          {
            label: isActiveSelection[PointSelectionType.DENM_REFERENCE_POINT]
              ? l10n.getString('forms-points-end-selection')
              : l10n.getString('forms-points-start-selection'),
            disabled: isSelectionButtonDisabled[PointSelectionType.DENM_REFERENCE_POINT],
            color: 'primary',
          },
        ]}
        onClick={toggleSelectionReference}
      />
      <ButtonGroup
        text={l10n.getString('forms-denm-history-zone')}
        buttons={[
          {
            label: isActiveSelection[PointSelectionType.DENM_HISTORY]
              ? l10n.getString('forms-points-end-selection')
              : l10n.getString('forms-points-start-selection'),
            disabled: isSelectionButtonDisabled[PointSelectionType.DENM_HISTORY],
            color: referencePointSelected && 'primary',
          },
        ]}
        onClick={toggleSelectionHistory}
      />

      <MainTrace
        deleteMainTrace={deleteMainTrace}
        toggleSelectionMainTrace={toggleSelectionMainTrace}
        mainTraceLength={mainTrace.length}
        isActiveSelection={isActiveSelection[PointSelectionType.DENM_TRACES]}
        isDisabled={isSelectionButtonDisabled[PointSelectionType.DENM_TRACES]}
        selectedTraceIndex={selectedTraceIndex}
        highlightTrace={highlightTrace}
      />

      {mainTrace.length > 0 && (
        <Trace
          isActiveSelection={isActiveSelection[PointSelectionType.DENM_TRACES]}
          toggleSelectionTraces={toggleSelectionTraces}
          isTracesZoneDisabled={isTracesZoneDisabled}
        />
      )}

      {formState.tracezoneCoordinates?.map((element, index) => (
        <TracesList
          key={index}
          deleteTrace={deleteTrace}
          highlightTrace={highlightTrace}
          index={index}
          selectedTraceIndex={selectedTraceIndex}
        />
      ))}
      <Box style={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 8 }}>
        <Tooltip title={l10n.getString('forms-denm-val-pd-tp-edit')} placement="top">
          <TextField
            id="latitude"
            label={l10n.getString('forms-denm-valid-period')}
            InputLabelProps={{ style: { fontSize: 12 } }}
            type="number"
            value={formState.validityperiod?.toString() ?? ''}
            onChange={updateValidityPeriod}
            style={{ marginTop: 16, gridColumn: 1 / 2 }}
          />
        </Tooltip>
        <SelectItem
          text={l10n.getString('forms-denm-period-unit')}
          value={formState.periodUnit ?? ''}
          options={[
            { value: 'seconds', label: l10n.getString('forms-denm-period-unit-seconds') },
            { value: 'minutes', label: l10n.getString('forms-denm-period-unit-minutes') },
            { value: 'hours', label: l10n.getString('forms-denm-period-unit-hours') },
          ]}
          onChange={updatePeriodUnit}
          tooltip={l10n.getString('forms-denm-val-pd-tp')}
          style={{ gridColumn: 2 / 3 }}
          tooltipPlacement="top"
        />
      </Box>
      <FreeformSelect
        label={l10n.getString('forms-denm-broadcasting-frequency')}
        presets={['1000', '5000', '10000']}
        disabled={busy}
        value={formState.frequencybroadcasting?.toString() ?? ''}
        numeric={true}
        onChange={updateFrequencyBroadcasting}
        tooltip={l10n.getString('forms-denm-br-fr-tp')}
      />

      <SelectItem
        text={l10n.getString('forms-rsu-road')}
        options={roads.map((road) => ({ label: road.name, value: road.id }))}
        value={formState.roadSegmentId ?? ''}
        onChange={(ev) => dispatch(denmFormReducer.actions.roadSegmentIdChanged(ev.target.value))}
        tooltip={l10n.getString('forms-rsu-road')}
      />

      <Divider style={{ margin: '32px 0' }} />

      <DenmOptionals
        denmCauses={denmCauses}
        showAlacarte={formState.cause === roadWorksId}
        isUpdate
      />

      <Button
        disabled={!isFormValid(formState) || busy || !mainTrace.length}
        variant="outlined"
        style={{ textTransform: 'none', marginTop: 32 }}
        onClick={submitForm}
      >
        {clone
          ? l10n.getString('forms-actions-clone-submit')
          : l10n.getString('forms-actions-update-submit')}
      </Button>
    </CardContent>
  );
};

export default React.memo(DenmManage);
