import { PayloadAction, current } from '@reduxjs/toolkit';
import {
  Coordinate,
  CreateDENMPayload,
  DisableDENMPayload,
  GetDENMListPaylaod,
  GetDENMPayload,
  Method,
  UpdateDENMPayload,
} from '_lib/api';
import fetchAPI from '_lib/api/fetchWrapper';
import apiRoute from '_lib/api/paths';
import { PointSelectionType, ToastTitle } from '_store/application/types';
import { differenceInMilliseconds } from 'date-fns';
import { SagaType, createSliceSaga } from 'redux-toolkit-saga';
import { delay, put, select } from 'typed-redux-saga';

import { getIsoDate } from '_store/utils';
import appReducer from '../application/reducer';
import { selectedRoadSegmentIdsSelector } from '../roads/selectors';
import denmsReducer from './denmsReducers';
import { DenmSourceFilter, DenmStatusFilter } from './denmsTypes';
import { denmsSelectorForSelectedRoadSegment } from './selectors';

const pageLimit = 100;

const getDenmDate = (date: string | null) => {
  if (date) {
    return getIsoDate(date);
  }
  const lastDay = new Date(Date.now() - 24 * 60 * 60 * 1000);
  return lastDay.toISOString();
};

interface DENMListPayloadProps {
  date: string;
  sources: DenmSourceFilter[];
  status: DenmStatusFilter | '';
  roadSegmentIds?: string[];
  causeId?: string;
  subCauseId?: string;
  createdFrom?: Date;
  createdTo?: Date;
  sincroDispatchable?: boolean;
  excludeCauseAndSubcause?: boolean;
  page?: number;
  limit?: number;
}

const getDenmRequestPayload = ({
  date,
  sources,
  status,
  roadSegmentIds,
  causeId,
  subCauseId,
  createdFrom,
  createdTo,
  sincroDispatchable,
  excludeCauseAndSubcause,
  page,
  limit,
}: DENMListPayloadProps) => {
  if (!sources || sources.length === 0) return;

  const dateString = getDenmDate(date);
  const roadSegments =
    roadSegmentIds && roadSegmentIds.length > 0 && sources.includes(DenmSourceFilter.EMERAS)
      ? roadSegmentIds.map((id) => `roadSegmentId=${id}`).join('&')
      : '';

  const sourceParams = sources.map((source) => `source=${source}`).join('&');
  const causeParams = causeId
    ? excludeCauseAndSubcause
      ? `&excludeCauseId=${causeId}`
      : `&causeId=${causeId}`
    : '';
  const subCauseParams = subCauseId
    ? excludeCauseAndSubcause
      ? `&excludeSubCauseId=${subCauseId}`
      : `&subCauseId=${subCauseId}`
    : '';
  const createdFromParams = createdFrom ? createdFrom.toISOString() : dateString;
  const createdToParams = createdTo ? `&createdBefore=${createdTo.toISOString()}` : '';
  const sincroDispatchableParams = sincroDispatchable
    ? `&sincroDispatchable=${sincroDispatchable}`
    : '';

  const statusToParams = {
    [DenmStatusFilter.IN_PROGRESS]: { active: true, disabled: false },
    [DenmStatusFilter.DISABLED]: { disabled: true, active: true },
    [DenmStatusFilter.CLOSED]: { active: false },
  };

  let statusParams = '';
  if (status !== '') {
    const params = statusToParams[status];
    statusParams = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
  }

  return {
    source: sourceParams,
    status: statusParams,
    roadSegments: roadSegments,
    causeId: causeParams,
    subCauseId: subCauseParams,
    createdFrom: createdFromParams,
    createdTo: createdToParams,
    sincroDispatchable: sincroDispatchableParams,
    page,
    limit,
  };
};

function* fetchDENMs({
  date,
  sources,
  status,
  roadSegmentIds,
  causeId,
  subCauseId,
  createdFrom,
  createdTo,
  sincroDispatchable,
  excludeCauseAndSubcause,
  page,
  limit,
}: DENMListPayloadProps) {
  const payload: DENMListPayloadProps = {
    date,
    sources,
    status,
    causeId,
    subCauseId,
    createdFrom,
    createdTo,
    sincroDispatchable,
    excludeCauseAndSubcause,
    page,
    limit,
  };

  if (roadSegmentIds && roadSegmentIds.length > 0) {
    payload.roadSegmentIds = roadSegmentIds;
  }

  const requestPayload = getDenmRequestPayload(payload);

  if (!requestPayload) {
    yield* put(denmsReducer.actions.removeAllDenms());
    return null;
  }

  return yield fetchAPI(apiRoute.getDENMs(requestPayload));
}

export function* getDENMEvent(payload: PayloadAction<GetDENMPayload>) {
  const { id } = payload.payload;
  try {
    const response = yield fetchAPI(apiRoute.getDENM(id));
    yield* put(denmsReducer.actions.denmResponse(response));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error getDENMEvent >>>>>>>', e);
    yield* put(denmsReducer.actions.denmResponse(null));
  }
}

export function* getDENMEvents(action: PayloadAction<GetDENMListPaylaod>) {
  const {
    roadSegmentIds,
    date,
    sources,
    causeId,
    subCauseId,
    createdFrom,
    createdTo,
    sincroDispatchable,
    getAllData,
    status,
    excludeCauseAndSubcause,
    currentPage,
    itemsPerPage,
  } = action.payload;

  function* createPayload(
    sources: DenmSourceFilter[],
    roadSegmentIds?: string[],
    page?: number,
    limit?: number,
  ) {
    const payload: DENMListPayloadProps = {
      date,
      sources,
      status,
      causeId,
      subCauseId,
      createdFrom,
      createdTo,
      sincroDispatchable,
      excludeCauseAndSubcause,
      page,
      limit,
    };

    if (roadSegmentIds && roadSegmentIds.length > 0) {
      payload.roadSegmentIds = roadSegmentIds;
    }

    return payload;
  }

  try {
    let resultsResponse = [];
    let totalResponse = 0;
    let page = 1;
    const limit = pageLimit;

    if (getAllData) {
      let response;
      do {
        response = yield* fetchDENMs(yield* createPayload(sources, roadSegmentIds, page, limit));
        resultsResponse = [...resultsResponse, ...response.results];
        page += 1;
      } while (response.total > resultsResponse.length);
    } else {
      const response = yield* fetchDENMs(
        yield* createPayload(sources, roadSegmentIds, currentPage, itemsPerPage),
      );
      if (response) {
        totalResponse = response.total;
        resultsResponse = response.results;
      }
    }

    if (getAllData) {
      yield* put(denmsReducer.actions.denmsAllDataResponse(resultsResponse));
    } else {
      yield* put(
        denmsReducer.actions.denmsResponse({ results: resultsResponse, total: totalResponse }),
      );
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error getDENMEvents >>>>>>>', e);
  }
}

export function* statusTimer(action: PayloadAction<any>) {
  const pendingTime = 30000;
  const date = new Date();
  const utcDate = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );

  // let roadSegmentId = yield* select(selectedRoadSegmentIdSelector);
  // const initialDENMs = yield* select(denmsSelectorForSelectedRoadSegment(roadSegmentId));

  const timeDelay = differenceInMilliseconds(new Date(action.payload.expiresAt), new Date(utcDate));
  yield delay(timeDelay);
  // roadSegmentId = yield* select(selectedRoadSegmentIdSelector);

  const currentDENMs = yield* select(
    denmsSelectorForSelectedRoadSegment(selectedRoadSegmentIdsSelector as unknown as string[]),
  );

  if (currentDENMs.find((d) => d.id === action.payload.id)) {
    yield put(
      denmsReducer.actions.denmUpsertOne({ ...action.payload, visibilityStatus: 'pending' }),
    );
    yield delay(pendingTime);
  }

  yield put(denmsReducer.actions.denmRemove(action.payload.id));
}

export function* createDENM(action: PayloadAction<CreateDENMPayload>) {
  yield put(appReducer.actions.dialogRequestStarted());
  // FIXME
  // const roads = yield* select(roadsSelector);
  let toastContent: ToastTitle;

  const params = action.payload;
  try {
    const result = yield fetchAPI(apiRoute.createDENM, {
      method: Method.POST,
      params: { ...params },
    });
    toastContent = {
      id: 'forms-denm-creation-success',
    };
    const { id, deleted, expiresAt, createdAt, updatedAt, disabled, source, metadata } = result;
    yield put(appReducer.actions.dialogRequestFinished(result)); // do not remove after websockets are implemented

    // TODO: When websockets are implemented, remove this
    if (process.env.NODE_ENV !== 'production') {
      const {
        traceZoneCoordinates: traceZoneParams,
        historyZoneCoordinates: historyZoneParams,
        referencePoint: referencePointParams,
      } = params;

      const traceZoneCoordinates = {
        ...traceZoneParams,
        bbox: traceZoneParams.bbox,
        coordinates: traceZoneParams.coordinates as Coordinate[][],
      };

      let historyZoneCoordinates = null;
      if (historyZoneParams) {
        historyZoneCoordinates = {
          ...historyZoneParams,
          bbox: historyZoneParams.bbox,
          coordinates: historyZoneParams.coordinates as Coordinate[],
        };
      }

      const referencePoint = {
        ...referencePointParams,
        bbox: referencePointParams.bbox,
        coordinates: referencePointParams.coordinates as Coordinate,
      };

      yield put(
        denmsReducer.actions.denmsAddOne({
          ...params,
          id,
          deleted,
          expiresAt,
          createdAt,
          updatedAt,
          traceZoneCoordinates,
          ...(historyZoneCoordinates && { historyZoneCoordinates }),
          referencePoint,
          disabled,
          source,
          metadata,
        }),
      );
    }
    // TODO: When websockets are implemented, remove this
  } catch (e) {
    toastContent = {
      id: 'forms-denm-creation-fail',
      args: { error: e?.packet?.message ?? 'Unknown error' },
    };
  } finally {
    yield put(appReducer.actions.removeMapElements(PointSelectionType.DENM_REFERENCE_POINT));
    yield put(appReducer.actions.removeMapElements(PointSelectionType.DENM_HISTORY));
    yield put(appReducer.actions.removeMapElements(PointSelectionType.DENM_TRACES));
    yield put(appReducer.actions.toastAdded(toastContent));
  }
}

export function* updateDENM(action: PayloadAction<UpdateDENMPayload>) {
  yield put(appReducer.actions.dialogRequestStarted());
  // FIXME
  // const roads = yield* select(roadsSelector);
  let toastContent: ToastTitle;

  try {
    const { id: denmId, denmPayload } = action.payload;
    const result = yield fetchAPI(apiRoute.updateDENM(denmId), {
      method: Method.PATCH,
      params: { ...denmPayload },
    });
    toastContent = {
      id: 'forms-denm-update-success',
    };
    const { id, deleted, expiresAt, createdAt, updatedAt, disabled, source, metadata } = result;
    yield put(appReducer.actions.dialogRequestFinished(result));

    // TODO: When websockets are implemented, remove this
    if (process.env.NODE_ENV !== 'production') {
      const {
        traceZoneCoordinates: traceZoneParams,
        historyZoneCoordinates: historyZoneParams,
        referencePoint: referencePointParams,
      } = denmPayload;

      const traceZoneCoordinates = {
        ...traceZoneParams,
        bbox: traceZoneParams.bbox,
        coordinates: traceZoneParams.coordinates as Coordinate[][],
      };

      let historyZoneCoordinates = null;
      if (historyZoneParams) {
        historyZoneCoordinates = {
          ...historyZoneParams,
          bbox: historyZoneParams.bbox,
          coordinates: historyZoneParams.coordinates as Coordinate[],
        };
      }

      const referencePoint = {
        ...referencePointParams,
        bbox: referencePointParams.bbox,
        coordinates: referencePointParams.coordinates as Coordinate,
      };

      yield put(
        denmsReducer.actions.denmUpsertOne({
          ...denmPayload,
          id,
          deleted,
          expiresAt,
          createdAt,
          updatedAt,
          traceZoneCoordinates,
          ...(historyZoneCoordinates && { historyZoneCoordinates }),
          referencePoint,
          disabled,
          source,
          metadata,
        }),
      );
    }
    // TODO: When websockets are implemented, remove this
  } catch (e) {
    toastContent = {
      id: 'forms-denm-update-fail',
      args: { error: e?.packet?.message ?? 'Unknown error' },
    };
  } finally {
    yield put(appReducer.actions.removeMapElements(PointSelectionType.DENM_REFERENCE_POINT));
    yield put(appReducer.actions.removeMapElements(PointSelectionType.DENM_HISTORY));
    yield put(appReducer.actions.removeMapElements(PointSelectionType.DENM_TRACES));
    yield put(appReducer.actions.toastAdded(toastContent));
  }
}

export function* deleteDENM(action: PayloadAction<string>) {
  yield* put(appReducer.actions.dialogRequestStarted());

  try {
    const id = action.payload;
    yield fetchAPI(apiRoute.deleteDENM(id), { method: Method.DELETE });
    yield* put(appReducer.actions.denmDeleted({ result: { ok: true }, id }));
    yield* put(appReducer.actions.dialogRequestFinished({ ok: true }));
    yield put(denmsReducer.actions.denmRemove(id));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error deleteDENM >>>>>>>', e);
  }
}

export function* deleteDENMs(action: PayloadAction<string[]>) {
  yield* put(appReducer.actions.dialogRequestStarted());

  try {
    const ids = action.payload;
    yield fetchAPI(apiRoute.deleteDENMs, { method: Method.POST, params: { eventIds: ids } });
    yield* put(appReducer.actions.denmDeleted({ result: { ok: true }, id: ids }));
    yield put(denmsReducer.actions.denmRemoveMany(ids));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error deleteDENM >>>>>>>', e);
  }
}

export function* toggleDisableDENM(action: PayloadAction<DisableDENMPayload>) {
  yield* put(appReducer.actions.dialogRequestStarted());

  try {
    const { ids, disable } = action.payload;
    yield fetchAPI(apiRoute.disableDENM(String(disable)), {
      method: Method.POST,
      params: { eventIds: ids },
    });
    yield* put(appReducer.actions.denmsDisabled({ result: { ok: true }, ids }));
    yield put(denmsReducer.actions.denmRemoveMany(ids));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error disableDENM >>>>>>>', e);
  }
}

function* causesRequest(_action: PayloadAction<void>) {
  try {
    const response = yield fetchAPI(apiRoute.causes);
    yield* put(denmsReducer.actions.causesResponse(response.results));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error causesRequest >>>>>>>', e);
  }
}

function* subCausesRequest(_action: PayloadAction<void>) {
  try {
    const response = yield fetchAPI(apiRoute.subCauses);
    yield* put(denmsReducer.actions.subCausesResponse(response.results));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error subCausesRequest >>>>>>>', e);
  }
}

function* subCausesByCauseRequest(action: PayloadAction<string>) {
  const causeId = action.payload;
  try {
    const response = yield fetchAPI(apiRoute.subCausesByCause(causeId));
    yield* put(denmsReducer.actions.subCausesByCauseResponse(response.results));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error subCausesByCauseRequest >>>>>>>', e);
  }
}

function* optionalSubCausesByCauseRequest(action: PayloadAction<string>) {
  const causeId = action.payload;
  try {
    const response = yield fetchAPI(apiRoute.subCausesByCause(causeId));
    yield* put(denmsReducer.actions.optionalSubCausesByCauseResponse(response.results));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error optionalSubCausesByCauseRequest >>>>>>>', e);
  }
}

export const denmsSagas = createSliceSaga({
  name: 'denms',
  caseSagas: {
    denmsRequest: getDENMEvents,
    denmRequest: getDENMEvent,
    createDENM,
    updateDENM,
    deleteDENM,
    deleteDENMs,
    disableDENM: toggleDisableDENM,
    causesRequest,
    subCausesRequest,
    subCausesByCauseRequest,
    optionalSubCausesByCauseRequest,
    statusTimer,
  },
  sagaType: SagaType.Watch,
});
