import { DialogType } from '_store/application/types';
import { RsuStatusCode } from '_store/devices/devicesTypes';
import React, { FC } from 'react';

interface IconMenuProps {
  type: DialogType;
  active: boolean;
}

const menuIconPath = (type: DialogType) => {
  switch (type) {
    case DialogType.HOME:
      return (
        <path
          d="
            M12.14 8
            c.218-.146.503-.146.722 0l6.17 4.16
            c.177.12.283.32.283.534
            v6.661
            c0 .171-.068.335-.19.456-.12.121-.285.189-.457.189h-3.392
            c-.172 0-.336-.068-.458-.189-.121-.12-.19-.285-.19-.456
            v-4.114
            c0-.356-.289-.645-.646-.645h-2.96
            c-.358 0-.647.289-.647.645
            v4.114
            c0 .171-.068.335-.19.456-.121.121-.286.189-.457.189H6.335
            c-.171 0-.336-.068-.457-.189-.121-.12-.19-.285-.19-.456
            v-6.664
            c0-.213.107-.413.284-.533
            zm0-3.89
            c.218-.147.503-.147.722 0l8.855 5.944
            c.152.103.253.266.277.448.025.182-.03.366-.15.506l-.733.83
            c-.214.241-.575.288-.844.109l-7.395-4.959
            c-.221-.15-.512-.15-.733 0l-7.402 4.964
            c-.27.179-.63.132-.845-.11l-.732-.83
            c-.122-.139-.178-.324-.155-.507.024-.183.126-.348.28-.451
            z"
        />
      );
    case DialogType.CREATE_IVIM:
      return (
        <path
          d="
            M11.82 2
            c-.232 0-.42.188-.42.42V4.1
            c0 .232.188.42.42.42.232 0 .42-.188.42-.42V2.42
            c0-.232-.188-.42-.42-.42
            zM5.88 4.46
            c-.107 0-.212.044-.294.126-.164.164-.164.426 0 .59l1.187 1.188
            c.164.164.427.164.591 0 .164-.164.164-.427 0-.59L6.176 4.585
            c-.082-.082-.188-.125-.295-.125
            zm11.88 0
            c-.108 0-.214.044-.296.126l-1.188 1.187
            c-.164.164-.164.427 0 .591.164.164.427.164.59 0l1.188-1.188
            c.164-.164.164-.426 0-.59-.082-.082-.187-.125-.295-.125
            zm-5.94 1.32
            c-2.561 0-5.04 1.987-5.04 4.797 0 3.647 2.73 4.41 2.73 6.963h4.62
            c0-2.553 2.73-3.316 2.73-6.963 0-2.81-2.479-4.797-5.04-4.797
            zm-1.293 1.273h.04
            c.202.001.376.147.411.347.036.2-.077.396-.267.467-1.023.386-1.793
            1.292-1.989 2.395-.041.229-.26.38-.488.338-.229-.042-.38-.26-.338-.489.248-1.397
            1.215-2.536 2.513-3.025.038-.017.077-.028.118-.033
            zM3.42 10.4
            c-.232 0-.42.188-.42.42 0 .232.188.42.42.42H5.1
            c.232 0 .42-.188.42-.42 0-.232-.188-.42-.42-.42H3.42
            zm15.12 0
            c-.232 0-.42.188-.42.42 0 .232.188.42.42.42h1.68
            c.232 0 .42-.188.42-.42 0-.232-.188-.42-.42-.42h-1.68
            zm-9.03 7.98
            v.84h4.62
            v-.84H9.51
            zm0 1.68
            c0 .483.397.84.84.84h2.94
            c.443 0 .84-.357.84-.84H9.51
            zm1.47 1.26
            c0 .465.375.84.84.84.465 0 .84-.375.84-.84h-1.68
            z"
        />
      );
    case DialogType.CREATE_DENM:
      return (
        <path
          d="
            M4.857.568
            c-.029 0-.058.003-.087.006-.428.05-.749.416-.74.847
            v.84h-2.6
            c-.43.043-.756.404-.754.835
            v13.395
            c.002.46.374.831.834.833h15.088
            c.46-.002.833-.374.835-.833V3.096
            c0-.46-.374-.834-.835-.836h-2.519
            v-.84
            c.005-.242-.095-.473-.274-.635-.179-.162-.419-.24-.659-.212-.429.049-.75.415-.743.846
            v.84H5.705
            v-.84
            c.004-.226-.084-.444-.244-.604-.16-.16-.377-.25-.604-.247
            zM2.353 3.935h13.404V5.61H2.353V3.935
            zm0 3.341h13.404
            v8.373H2.353V7.276
            zm2.51 1.676
            c-.303-.007-.586.151-.74.413-.154.262-.154.586 0 .848.154.261.437.42.74.413h4.195
            c.304.006.587-.152.741-.413.154-.262.154-.586 0-.848s-.437-.42-.74-.413H4.863
            zm0 3.348
            c-.303-.007-.587.15-.741.412-.154.262-.154.587 0 .849s.438.42.742.412h8.38
            c.305.008.589-.15.743-.412.154-.262.154-.587 0-.849s-.438-.42-.742-.412H4.864
            z"
          transform="translate(3 3)"
        />
      );
    case DialogType.DEVICE:
      return (
        <path
          d="
            M18.514,11.66
            L4.684,11.66
            C4.50099844,11.6615844 4.32612234,11.7358046 4.19784597,11.8663314
            C4.0695696,11.9968582 3.99840176,12.1729986 3.99997365,12.356
            L3.99997365,19.304
            L19.200012,19.304
            L19.200012,12.356
            C19.202219,11.9745916 18.8954006,11.6633006 18.514,11.66
            Z M5.752,13.86
            C5.58224453,13.8632495 5.42738851,13.7634932 5.36016189,13.6075828
            C5.29293527,13.4516723 5.32669584,13.2705869 5.44558755,13.1493753
            C5.56447926,13.0281638 5.74487857,12.9909106 5.90205883,13.0551122
            C6.05923909,13.1193138 6.1619689,13.2722134 6.162,13.442
            C6.16414002,13.5526768 6.12208002,13.6596423 6.04512991,13.73922
            C5.9681798,13.8187976 5.86268624,13.8644241 5.752,13.866
            L5.752,13.86
            Z M7.908,13.86
            C7.73824453,13.8632495 7.58338851,13.7634932 7.51616189,13.6075828
            C7.44893527,13.4516723 7.48269584,13.2705869 7.60158755,13.1493753
            C7.72047926,13.0281638 7.90087857,12.9909106 8.05805883,13.0551122
            C8.21523909,13.1193138 8.3179689,13.2722134 8.318,13.442
            C8.32014002,13.5526768 8.27808002,13.6596423 8.20112991,13.73922
            C8.1241798,13.8187976 8.01868624,13.8644241 7.908,13.866
            L7.908,13.86
            Z M10.064,13.86
            C9.89424453,13.8632495 9.73938851,13.7634932 9.67216189,13.6075828
            C9.60493527,13.4516723 9.63869584,13.2705869 9.75758755,13.1493753
            C9.87647926,13.0281638 10.0568786,12.9909106 10.2140588,13.0551122
            C10.3712391,13.1193138 10.4739689,13.2722134 10.474,13.442
            C10.47614,13.5526768 10.43408,13.6596423 10.3571299,13.73922
            C10.2801798,13.8187976 10.1746862,13.8644241 10.064,13.866
            L10.064,13.86
            Z M19.2,19.422
            L19.2,19.768
            L19.1920808,19.8689192
            C19.1418372,20.1982402 18.8588885,20.4510239 18.516,20.454
            L18.516,20.454
            L4.684,20.454
            C4.30448772,20.4507014 3.99888559,20.141525 3.99999696,19.762
            L3.99999696,19.762
            L3.99999696,19.422
            L19.2,19.422
            Z M17.5985449,13.0552776
            C17.7554787,13.1196087 17.8579825,13.2723925 17.858,13.442
            C17.8601403,13.5530193 17.8178074,13.6602868 17.7404253,13.7399227
            C17.6630432,13.8195586 17.557035,13.8649529 17.446,13.866
            L17.446,13.86
            C17.27641,13.8624346 17.1221602,13.7621502 17.0555671,13.6061629
            C16.9889739,13.4501756 17.02324,13.2694112 17.1423005,13.1486168
            C17.261361,13.0278223 17.4416111,12.9909465 17.5985449,13.0552776
            Z M17.442,4.658
            C17.5315444,4.65746055 17.618237,4.68946246 17.686,4.748
            C17.7497595,4.80262348 17.7869217,4.88204863 17.788,4.966
            L17.788,4.966
            L18,11.46
            L16.8,11.46
            L17.094,4.966
            C17.105787,4.78521294 17.2611202,4.64773411 17.442,4.658
            Z M5.442,4.658
            C5.53154439,4.65746055 5.61823703,4.68946246 5.686,4.748
            C5.74975948,4.80262348 5.78692171,4.88204863 5.788,4.966
            L5.788,4.966
            L6,11.46
            L4.8,11.46
            L5.094,4.966
            C5.10578697,4.78521294 5.26112019,4.64773411 5.442,4.658
            Z M11.442,4.658
            C11.5315444,4.65746055 11.618237,4.68946246 11.686,4.748
            C11.7497595,4.80262348 11.7869217,4.88204863 11.788,4.966
            L11.788,4.966
            L12,11.46
            L10.8,11.46
            L11.094,4.966
            C11.105787,4.78521294 11.2611202,4.64773411 11.442,4.658
            Z"
          id="Shape"
        />
      );
    case DialogType.SETTINGS:
      return (
        <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
      );
    default:
      return (
        <path
          d="
            M20.2444,8.3337
            C20.3985617,8.26861741 20.5751299,8.2855164 20.7141482,8.37865865
            C20.8531665,8.4718009 20.9359544,8.62867055 20.9344215,8.796
            L20.9344215,8.796
            L20.9344215,16.5723
            C20.9274873,16.9845807 20.6666533,17.3497483 20.2789,17.49
            L20.2789,17.49
            L15.9595,19.2564
            L15.9595,10.2864
            Z M7.2849,8.5614
            C7.47512079,9.06014129 7.70589309,9.54245541 7.9749,10.0035
            L7.9749,10.0035
            L7.9749,17.2416
            L3.69,19.1943
            C3.53583834,19.2593826 3.35927008,19.2424836 3.22025179,19.1493414
            C3.08123351,19.0561991 2.99844559,18.8993295 2.99997849,18.732
            L2.99997849,18.732
            L2.99997849,10.9557
            C3.01209368,10.5344204 3.28864754,10.1666038 3.69,10.038
            L3.69,10.038
            Z M14.948,11.5905
            L14.948,19.2288
            L8.9864,17.2416
            L8.9864,11.5905
            C9.5729,12.4323 10.2215,13.281 10.8356,13.971
            C11.115528,14.3069129 11.5299398,14.5014843 11.9672,14.5023
            C12.4044602,14.5014843 12.818872,14.3069129 13.0988,13.971
            C13.7129,13.2534 14.3615,12.4323 14.948,11.5905
            L14.948,11.5905
            Z M11.9672,3.3312
            C14.1279036,3.3312 15.8795,5.08279637 15.8795,7.2435
            C15.8795,8.9892 13.3196,12.177 12.3398,13.3293
            C12.248047,13.4405354 12.111394,13.5049594 11.9672,13.5049594
            C11.823006,13.5049594 11.686353,13.4405354 11.5946,13.3293
            C10.6148,12.177 8.0549,8.9892 8.0549,7.2435
            C8.0549,5.08279637 9.80649637,3.3312 11.9672,3.3312
            Z"
          id="Combined-Shape"
        />
      );
  }
};
export const IconMenu: FC<IconMenuProps> = ({ type, active }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none">
      <g fill={active ? '#005ABB' : '#82919b'}>{menuIconPath(type)}</g>
    </g>
  </svg>
);

export const RsuStatus: FC<{ status?: RsuStatusCode }> = ({ status }) => {
  let color = null;

  switch (status) {
    case RsuStatusCode.UP:
      color = 'green';
      break;
    case RsuStatusCode.WARNING:
      color = 'yellow';
      break;
    case RsuStatusCode.ERROR:
      color = 'red';
      break;
    default:
      color = '#82919b';
  }

  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="UX-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="RSU-lista_search" transform="translate(-402.000000, -372.000000)">
          <g id="component/Rsu/close" transform="translate(140.000000, 361.000000)">
            <g id="Group" transform="translate(262.000000, 11.000000)">
              <circle
                id="Oval"
                stroke={color}
                fillOpacity="0.5"
                fill="#ffffff"
                cx="11"
                cy="11"
                r="10.5"
              ></circle>
              <g id="icon/device" transform="translate(3.000000, 3.000000)" fill={color}>
                <path
                  d="M12.3426667,7.77333333
                    L3.12266667,7.77333333
                    C3.00066563,7.7743896 2.88408156,7.8238697 2.79856398,7.91088759
                    C2.7130464,7.99790548 2.66560118,8.11533237 2.6666491,8.23733333
                    L2.6666491,12.8693333
                    L12.800008,12.8693333
                    L12.800008,8.23733333
                    C12.8014793,7.98306104 12.5969337,7.77553374 12.3426667,7.77333333
                    Z M3.83466667,9.24
                    C3.72149635,9.24216633 3.61825901,9.17566212 3.57344126,9.07172184
                    C3.52862351,8.96778155 3.55113056,8.84705793 3.6303917,8.76625023
                    C3.70965284,8.68544253 3.82991905,8.66060708 3.93470589,8.70340814
                    C4.03949273,8.7462092 4.10797927,8.84814229 4.108,8.96133333
                    C4.10942668,9.03511784 4.08138668,9.10642819 4.03008661,9.15947997
                    C3.97878653,9.21253176 3.90845749,9.24294939 3.83466667,9.244
                    L3.83466667,9.24
                    Z M5.272,9.24
                    C5.15882969,9.24216633 5.05559234,9.17566212 5.01077459,9.07172184
                    C4.96595684,8.96778155 4.98846389,8.84705793 5.06772503,8.76625023
                    C5.14698617,8.68544253 5.26725238,8.66060708 5.37203922,8.70340814
                    C5.47682606,8.7462092 5.5453126,8.84814229 5.54533333,8.96133333
                    C5.54676001,9.03511784 5.51872001,9.10642819 5.46741994,9.15947997
                    C5.41611987,9.21253176 5.34579082,9.24294939 5.272,9.244
                    L5.272,9.24
                    Z M6.70933333,9.24
                    C6.59616302,9.24216633 6.49292567,9.17566212 6.44810793,9.07172184
                    C6.40329018,8.96778155 6.42579722,8.84705793 6.50505836,8.76625023
                    C6.5843195,8.68544253 6.70458571,8.66060708 6.80937255,8.70340814
                    C6.91415939,8.7462092 6.98264593,8.84814229 6.98266667,8.96133333
                    C6.98409334,9.03511784 6.95605335,9.10642819 6.90475327,9.15947997
                    C6.8534532,9.21253176 6.78312416,9.24294939 6.70933333,9.244
                    L6.70933333,9.24
                    Z M12.8,12.948
                    L12.8,13.1786667
                    L12.7922589,13.2605057
                    C12.7528214,13.4729011 12.5675125,13.63406 12.344,13.636
                    L12.344,13.636
                    L3.12266667,13.636
                    C2.86965848,13.6338009 2.66592373,13.4276833 2.66666464,13.1746667
                    L2.66666464,13.1746667
                    L2.66666464,12.948
                    L12.8,12.948
                    Z M11.7323633,8.70351841
                    C11.8369858,8.74640581 11.9053217,8.84826168 11.9053333,8.96133333
                    C11.9067602,9.03534621 11.8785383,9.10685785 11.8269502,9.15994847
                    C11.7753621,9.2130391 11.70469,9.24330196 11.6306667,9.244
                    L11.6306667,9.24
                    C11.5176067,9.24162304 11.4147735,9.17476681 11.370378,9.07077526
                    C11.3259826,8.96678371 11.3488266,8.84627411 11.4282003,8.7657445
                    C11.507574,8.6852149 11.6277408,8.66063101 11.7323633,8.70351841
                    Z M11.628,3.10533333
                    C11.6876963,3.1049737 11.7454914,3.12630831 11.7906667,3.16533333
                    C11.833173,3.20174898 11.8579478,3.25469908 11.8586667,3.31066667
                    L11.8586667,3.31066667
                    L12,7.64
                    L11.2,7.64
                    L11.396,3.31066667
                    C11.403858,3.19014196 11.5074135,3.09848941 11.628,3.10533333
                    Z M3.628,3.10533333
                    C3.68769626,3.1049737 3.74549136,3.12630831 3.79066667,3.16533333
                    C3.83317299,3.20174898 3.85794781,3.25469908 3.85866667,3.31066667
                    L3.85866667,3.31066667
                    L4,7.64
                    L3.2,7.64
                    L3.396,3.31066667
                    C3.40385798,3.19014196 3.50741346,3.09848941 3.628,3.10533333
                    Z M7.628,3.10533333
                    C7.68769626,3.1049737 7.74549136,3.12630831 7.79066667,3.16533333
                    C7.83317299,3.20174898 7.85794781,3.25469908 7.85866667,3.31066667
                    L7.85866667,3.31066667
                    L8,7.64
                    L7.2,7.64
                    L7.396,3.31066667
                    C7.40385798,3.19014196 7.50741346,3.09848941 7.628,3.10533333
                    Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

interface IvimIconProps {
  active: boolean;
}
export const IvimIcon: FC<IvimIconProps> = ({ active }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none">
      <g fill={!active ? '#82919b' : '#005ABB'}>
        <path
          d="
            M11.82 2
            c-.232 0-.42.188-.42.42V4.1
            c0 .232.188.42.42.42.232 0 .42-.188.42-.42V2.42
            c0-.232-.188-.42-.42-.42
            zM5.88 4.46
            c-.107 0-.212.044-.294.126-.164.164-.164.426 0 .59l1.187 1.188
            c.164.164.427.164.591 0 .164-.164.164-.427 0-.59L6.176 4.585
            c-.082-.082-.188-.125-.295-.125
            zm11.88 0
            c-.108 0-.214.044-.296.126l-1.188 1.187
            c-.164.164-.164.427 0 .591.164.164.427.164.59 0l1.188-1.188
            c.164-.164.164-.426 0-.59-.082-.082-.187-.125-.295-.125
            zm-5.94 1.32
            c-2.561 0-5.04 1.987-5.04 4.797 0 3.647 2.73 4.41 2.73 6.963h4.62
            c0-2.553 2.73-3.316 2.73-6.963 0-2.81-2.479-4.797-5.04-4.797
            zm-1.293 1.273h.04
            c.202.001.376.147.411.347.036.2-.077.396-.267.467-1.023.386-1.793
            1.292-1.989 2.395-.041.229-.26.38-.488.338-.229-.042-.38-.26-.338-.489.248-1.397
            1.215-2.536 2.513-3.025.038-.017.077-.028.118-.033
            zM3.42 10.4
            c-.232 0-.42.188-.42.42 0 .232.188.42.42.42H5.1
            c.232 0 .42-.188.42-.42 0-.232-.188-.42-.42-.42H3.42
            zm15.12 0
            c-.232 0-.42.188-.42.42 0 .232.188.42.42.42h1.68
            c.232 0 .42-.188.42-.42 0-.232-.188-.42-.42-.42h-1.68
            zm-9.03 7.98
            v.84h4.62
            v-.84H9.51
            zm0 1.68
            c0 .483.397.84.84.84h2.94
            c.443 0 .84-.357.84-.84H9.51
            zm1.47 1.26
            c0 .465.375.84.84.84.465 0 .84-.375.84-.84h-1.68
            z"
        />
      </g>
    </g>
  </svg>
);

interface IconDenmProps {
  active: boolean;
}
export const IconDenm: FC<IconDenmProps> = ({ active }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none">
      <g fill={!active ? '#82919b' : '#29c706'}>
        <path
          d="
            M4.857.568
            c-.029 0-.058.003-.087.006-.428.05-.749.416-.74.847
            v.84h-2.6
            c-.43.043-.756.404-.754.835
            v13.395
            c.002.46.374.831.834.833h15.088
            c.46-.002.833-.374.835-.833V3.096
            c0-.46-.374-.834-.835-.836h-2.519
            v-.84
            c.005-.242-.095-.473-.274-.635-.179-.162-.419-.24-.659-.212-.429.049-.75.415-.743.846
            v.84H5.705
            v-.84
            c.004-.226-.084-.444-.244-.604-.16-.16-.377-.25-.604-.247
            zM2.353 3.935h13.404V5.61H2.353V3.935
            zm0 3.341h13.404
            v8.373H2.353V7.276
            zm2.51 1.676
            c-.303-.007-.586.151-.74.413-.154.262-.154.586 0 .848.154.261.437.42.74.413h4.195
            c.304.006.587-.152.741-.413.154-.262.154-.586 0-.848s-.437-.42-.74-.413H4.863
            zm0 3.348
            c-.303-.007-.587.15-.741.412-.154.262-.154.587 0 .849s.438.42.742.412h8.38
            c.305.008.589-.15.743-.412.154-.262.154-.587 0-.849s-.438-.42-.742-.412H4.864
            z"
          transform="translate(3 3)"
        />
      </g>
    </g>
  </svg>
);

interface IconDeviceProps {
  active: boolean;
}
export const IconDevice: FC<IconDeviceProps> = ({ active }) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none">
      <g fill={!active ? '#82919b' : '#005ABB'}>
        <path
          d="
            M18.514,11.66
            L4.684,11.66
            C4.50099844,11.6615844 4.32612234,11.7358046 4.19784597,11.8663314
            C4.0695696,11.9968582 3.99840176,12.1729986 3.99997365,12.356
            L3.99997365,19.304
            L19.200012,19.304
            L19.200012,12.356
            C19.202219,11.9745916 18.8954006,11.6633006 18.514,11.66
            Z
            M5.752,13.86
            C5.58224453,13.8632495 5.42738851,13.7634932 5.36016189,13.6075828
            C5.29293527,13.4516723 5.32669584,13.2705869 5.44558755,13.1493753
            C5.56447926,13.0281638 5.74487857,12.9909106 5.90205883,13.0551122
            C6.05923909,13.1193138 6.1619689,13.2722134 6.162,13.442
            C6.16414002,13.5526768 6.12208002,13.6596423 6.04512991,13.73922
            C5.9681798,13.8187976 5.86268624,13.8644241 5.752,13.866
            L5.752,13.86
            Z
            M7.908,13.86
            C7.73824453,13.8632495 7.58338851,13.7634932 7.51616189,13.6075828
            C7.44893527,13.4516723 7.48269584,13.2705869 7.60158755,13.1493753
            C7.72047926,13.0281638 7.90087857,12.9909106 8.05805883,13.0551122
            C8.21523909,13.1193138 8.3179689,13.2722134 8.318,13.442
            C8.32014002,13.5526768 8.27808002,13.6596423 8.20112991,13.73922
            C8.1241798,13.8187976 8.01868624,13.8644241 7.908,13.866
            L7.908,13.86
            Z
            M10.064,13.86
            C9.89424453,13.8632495 9.73938851,13.7634932 9.67216189,13.6075828
            C9.60493527,13.4516723 9.63869584,13.2705869 9.75758755,13.1493753
            C9.87647926,13.0281638 10.0568786,12.9909106 10.2140588,13.0551122
            C10.3712391,13.1193138 10.4739689,13.2722134 10.474,13.442
            C10.47614,13.5526768 10.43408,13.6596423 10.3571299,13.73922
            C10.2801798,13.8187976 10.1746862,13.8644241 10.064,13.866
            L10.064,13.86
            Z M19.2,19.422
            L19.2,19.768
            L19.1920808,19.8689192
            C19.1418372,20.1982402 18.8588885,20.4510239 18.516,20.454
            L18.516,20.454
            L4.684,20.454
            C4.30448772,20.4507014 3.99888559,20.141525 3.99999696,19.762
            L3.99999696,19.762
            L3.99999696,19.422
            L19.2,19.422
            Z M17.5985449,13.0552776
            C17.7554787,13.1196087 17.8579825,13.2723925 17.858,13.442
            C17.8601403,13.5530193 17.8178074,13.6602868 17.7404253,13.7399227
            C17.6630432,13.8195586 17.557035,13.8649529 17.446,13.866
            L17.446,13.86
            C17.27641,13.8624346 17.1221602,13.7621502 17.0555671,13.6061629
            C16.9889739,13.4501756 17.02324,13.2694112 17.1423005,13.1486168
            C17.261361,13.0278223 17.4416111,12.9909465 17.5985449,13.0552776
            Z M17.442,4.658
            C17.5315444,4.65746055 17.618237,4.68946246 17.686,4.748
            C17.7497595,4.80262348 17.7869217,4.88204863 17.788,4.966
            L17.788,4.966
            L18,11.46
            L16.8,11.46
            L17.094,4.966
            C17.105787,4.78521294 17.2611202,4.64773411 17.442,4.658
            Z M5.442,4.658
            C5.53154439,4.65746055 5.61823703,4.68946246 5.686,4.748
            C5.74975948,4.80262348 5.78692171,4.88204863 5.788,4.966
            L5.788,4.966
            L6,11.46
            L4.8,11.46
            L5.094,4.966
            C5.10578697,4.78521294 5.26112019,4.64773411 5.442,4.658
            Z M11.442,4.658
            C11.5315444,4.65746055 11.618237,4.68946246 11.686,4.748
            C11.7497595,4.80262348 11.7869217,4.88204863 11.788,4.966
            L11.788,4.966
            L12,11.46
            L10.8,11.46
            L11.094,4.966
            C11.105787,4.78521294 11.2611202,4.64773411 11.442,4.658
            Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);

interface IconMapProps {
  active: boolean;
}
export const IconMap: FC<IconMapProps> = ({ active }) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none">
      <g fill={!active ? '#82919b' : '#005ABB'}>
        <path
          d="M20.2444,8.3337
            C20.3985617,8.26861741 20.5751299,8.2855164 20.7141482,8.37865865
            C20.8531665,8.4718009 20.9359544,8.62867055 20.9344215,8.796
            L20.9344215,8.796
            L20.9344215,16.5723
            C20.9274873,16.9845807 20.6666533,17.3497483 20.2789,17.49
            L20.2789,17.49
            L15.9595,19.2564
            L15.9595,10.2864
            Z
            M7.2849,8.5614
            C7.47512079,9.06014129 7.70589309,9.54245541 7.9749,10.0035
            L7.9749,10.0035
            L7.9749,17.2416
            L3.69,19.1943
            C3.53583834,19.2593826 3.35927008,19.2424836 3.22025179,19.1493414
            C3.08123351,19.0561991 2.99844559,18.8993295 2.99997849,18.732
            L2.99997849,18.732
            L2.99997849,10.9557
            C3.01209368,10.5344204 3.28864754,10.1666038 3.69,10.038
            L3.69,10.038
            Z
            M14.948,11.5905
            L14.948,19.2288
            L8.9864,17.2416
            L8.9864,11.5905
            C9.5729,12.4323 10.2215,13.281 10.8356,13.971
            C11.115528,14.3069129 11.5299398,14.5014843 11.9672,14.5023
            C12.4044602,14.5014843 12.818872,14.3069129 13.0988,13.971
            C13.7129,13.2534 14.3615,12.4323 14.948,11.5905
            L14.948,11.5905
            Z M11.9672,3.3312
            C14.1279036,3.3312 15.8795,5.08279637 15.8795,7.2435
            C15.8795,8.9892 13.3196,12.177 12.3398,13.3293
            C12.248047,13.4405354 12.111394,13.5049594 11.9672,13.5049594
            C11.823006,13.5049594 11.686353,13.4405354 11.5946,13.3293
            C10.6148,12.177 8.0549,8.9892 8.0549,7.2435
            C8.0549,5.08279637 9.80649637,3.3312 11.9672,3.3312
            Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
);

export const AlertIcon: FC = () => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 40 49"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>68D87533-046B-453A-A71F-8F73A6A3E6C1</title>
    <g id="UX-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Creazione-Denm_modale" transform="translate(-393.000000, -407.000000)" fill="#444E53">
        <g id="icon/alert" transform="translate(393.000000, 407.500000)">
          <path
            d="
              M24,4
              C12.954305,4 4,12.954305 4,24
              C4,35.045695 12.954305,44 24,44
              C35.045695,44 44,35.045695 44,24
              C44,12.954305 35.045695,4 24,4
              L24,4
              L24,4
              L24,4
              L24,4
              Z
              M22.1818182,25.8181818
              L22.1818182,14.9090909
              L25.8181818,14.9090909
              L25.8181818,25.8181818
              L22.1818182,25.8181818
              L22.1818182,25.8181818
              L22.1818182,25.8181818
              Z M25.8181818,33.0909091
              L22.1818182,33.0909091
              L22.1818182,29.4545455
              L25.8181818,29.4545455
              L25.8181818,33.0909091
              L25.8181818,33.0909091
              L25.8181818,33.0909091
              Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export const AlertIconRed: FC = () => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 40 49"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>68D87533-046B-453A-A71F-8F73A6A3E6C1</title>
    <g id="UX-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Creazione-Denm_modale" transform="translate(-393.000000, -407.000000)" fill="#cd3a3a">
        <g id="icon/alert" transform="translate(393.000000, 407.500000)">
          <path
            d="
              M24,4
              C12.954305,4 4,12.954305 4,24
              C4,35.045695 12.954305,44 24,44
              C35.045695,44 44,35.045695 44,24
              C44,12.954305 35.045695,4 24,4
              L24,4
              L24,4
              L24,4
              L24,4
              Z
              M22.1818182,25.8181818
              L22.1818182,14.9090909
              L25.8181818,14.9090909
              L25.8181818,25.8181818
              L22.1818182,25.8181818
              L22.1818182,25.8181818
              L22.1818182,25.8181818
              Z
              M25.8181818,33.0909091
              L22.1818182,33.0909091
              L22.1818182,29.4545455
              L25.8181818,29.4545455
              L25.8181818,33.0909091
              L25.8181818,33.0909091
              L25.8181818,33.0909091
              Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

const eventColors = {
  scheduled: 'b900ff',
  progress: '4fca4f',
  terminated: '42414b',
  default: '82919b',
  disabled: 'ffa500',
};
export const eventIcon = (base64data: string, status?: string): string => {
  const base64Icon = `data:image/png;base64,${base64data}`;
  const colorBg = status in eventColors ? eventColors[status] : eventColors.default;

  return `<svg
    width="500px"
    height="589px"
    viewBox="0 0 500 589"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
      <circle id="Oval" cx="250" cy="250" r="250" fill="%23${colorBg}"></circle>
      <path
        d="
          M250,322
          C269,322 313, 411 383, 588
          L116,588
          C186,411 230,322 250,322
          Z"
        id="Triangle"
        transform="
          translate(250.000000, 455.555556)
          scale(-1, 1)
          rotate(-180.000000)
          translate(-250.000000,
          -455.555556)"
        fill="%23${colorBg}"></path>
      <image href="${base64Icon}" x="58" y="1" width="75%" height="75%"/>
  </svg>`;
};
