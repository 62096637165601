/* eslint-disable simple-import-sort/imports */
import L from 'leaflet';
import React, { FC, ReactElement } from 'react';
import { Marker, Polyline } from 'react-leaflet';
import { Cam } from '_store/vehicles/vehiclesTypes';

import { events } from '../../../icons/utils/cam';
import camIcons from '../../../icons/cam';
import CamInfoTooltip from '../InfoTooltips/CamInfoTooltip';
import { HoveringElementType } from '../InfoTooltips/types';
import { iconSizePx, toLatLng } from '../utils';

export interface VehiclesProps {
  vehicles: Cam[];
}

const Vehicles: FC<VehiclesProps> = ({ vehicles }): ReactElement => (
  <>
    {vehicles &&
      vehicles.map((cam) => {
        const icon =
          camIcons[cam.stationType in events ? events[cam.stationType].icon : 'unknown.png'];
        return (
          <React.Fragment key={`vehicle-${cam.stationId}`}>
            {cam.pathHistory?.coordinates.length > 1 && (
              <Polyline
                positions={cam.pathHistory.coordinates.map((c) => toLatLng(c))}
                weight={2}
                // geodesic: true,
                // zIndex: 16,
              />
            )}
            <Marker
              position={toLatLng(cam.referencePoint.coordinates)}
              icon={
                new L.Icon({
                  iconSize: new L.Point(iconSizePx, iconSizePx),
                  iconUrl: icon,
                  iconAnchor: new L.Point(19, 19),
                })
              }
            >
              <CamInfoTooltip
                hoveringElement={{
                  type: HoveringElementType.CAM,
                  cam,
                }}
              />
            </Marker>
          </React.Fragment>
        );
      })}
  </>
);

export default React.memo(Vehicles);
