import { useAppDispatch } from '_store';
import React, { FC, useEffect, useState } from 'react';

import { useLocalization } from '@fluent/react';
import { Box, CardContent, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { Obu } from '_lib/api';
import { DenmSourceFilter } from '_store/denm/denmsTypes';
import { settingsSelector } from '_store/settings/selectors';
import settingsReducer from '_store/settings/settingsReducers';
import { useSelector } from 'react-redux';
import styles from './styles';
import { ObuForm } from './ObuForm';

const SettingsLayout: FC = ({}) => {
  const classes = styles();
  const { l10n } = useLocalization();
  const dispatch = useAppDispatch();

  const settings = useSelector(settingsSelector);
  const [obuList, setObuList] = useState<Obu[]>(undefined);

  const handleObusChange = (updatedObus: Obu[]) => {
    setObuList(updatedObus);
    const obuPayload = updatedObus.reduce((acc, obu) => {
      if (obu.id && obu.name) {
        acc[obu.id] = obu.name;
      }
      return acc;
    }, {} as Record<string, string>);

    dispatch(
      settingsReducer.actions.updateSettings({
        storage: {
          cam: {
            obu: obuPayload,
          },
        },
      }),
    );
  };

  useEffect(() => {
    setObuList(settings.storage.cam.obu);
  }, [settings.storage.cam.obu]);

  const handleDistributionChange =
    (sourceFilter: DenmSourceFilter) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;
      dispatch(
        settingsReducer.actions.updateSettings({
          distribution: {
            denm: {
              source: sourceFilter,
              value: value,
            },
          },
        }),
      );
    };

  const handleObuFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    dispatch(
      settingsReducer.actions.updateSettings({
        storage: {
          cam: {
            obuFilterEnabled: value,
          },
        },
      }),
    );
  };

  return (
    <CardContent className={classes.cardContent}>
      <Box className={classes.box}>
        <Typography variant="caption" className={classes.heading}>
          {l10n.getString('forms-settings-denms-distribution')}
        </Typography>
      </Box>
      <Box className={classes.containerBox}>
        <Box className={classes.box}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.distribution.denm.emeras}
                onChange={handleDistributionChange(DenmSourceFilter.EMERAS)}
                name="distributionEmeras"
                color="primary"
                size="small"
              />
            }
            label={l10n.getString('forms-settings-distribution-emeras')}
            classes={{ label: classes.switch }}
          />
        </Box>
        <Box className={classes.box}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.distribution.denm.datexii}
                onChange={handleDistributionChange(DenmSourceFilter.DATEXII)}
                name="distributionDatexii"
                color="primary"
                size="small"
              />
            }
            label={l10n.getString('forms-settings-distribution-datexii')}
            classes={{ label: classes.switch }}
          />
        </Box>
      </Box>
      <Box className={classes.box}>
        <Typography variant="caption" className={classes.heading}>
          {l10n.getString('forms-settings-denms-distribution-v2i')}
        </Typography>
      </Box>
      <Box className={classes.containerBox}>
        <Box className={classes.box}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.distribution.denm.connex}
                onChange={handleDistributionChange(DenmSourceFilter.CONNEX)}
                name="distributionConnex"
                color="primary"
                size="small"
              />
            }
            label={l10n.getString('forms-settings-distribution-connex')}
            classes={{ label: classes.switch }}
          />
        </Box>
      </Box>
      <Box className={classes.box}>
        <Typography variant="caption" className={classes.heading}>
          {l10n.getString('forms-settings-obu-configuration')}
        </Typography>
      </Box>
      <Box className={classes.containerBox}>
        <Box className={classes.box}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.storage.cam.obuFilterEnabled}
                onChange={handleObuFilterChange}
                name="obuConfiguration"
                color="primary"
                size="small"
              />
            }
            label={l10n.getString('forms-settings-enable-obu-filters')}
            classes={{ label: classes.switch }}
          />
        </Box>
        {settings.storage.cam.obuFilterEnabled && obuList && (
          <ObuForm obuList={obuList} onObusChange={handleObusChange} />
        )}
      </Box>
    </CardContent>
  );
};

export default React.memo(SettingsLayout);
