import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '..';
import { camAdapter } from './vehiclesReducers';

const camStateDomain = (state: RootState) => state.vehicles;

export const camsSelector = createSelector(camStateDomain, (cam) =>
  camAdapter.getSelectors().selectAll(cam),
);

export default {
  camsSelector,
};
