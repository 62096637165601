import { DialogType } from '_store/application/types';
import React, { FC } from 'react';

import { useLocalization } from '@fluent/react';
import { CardContent, Grid, Typography } from '@material-ui/core';
import { useAppDispatch } from '_store';
import ivimsReducer from '_store/ivim/ivimsReducers';
import { ivimType } from '_store/ivim/ivimsTypes';
import { ivimsSelector } from '_store/ivim/selectors';
import { isFuture } from 'date-fns';
import { useSelector } from 'react-redux';
import * as ivimEvents from '../../../assets/ivimEvents.json';
import EventLayout from './EventLayout';
import styles from './styles';
import {
  mapPictogramText,
  mapServiceCategoryCode,
  mapServiceCategorySubCode,
} from '_store/ivim/utils';

interface IvimLayoutProps {
  id: string;
  onUpdateIvim?: (idExpanded: string) => void;
  onCloneIvim?: (idExpanded: string) => void;
}

const IvimLayout: FC<IvimLayoutProps> = ({ id, onUpdateIvim, onCloneIvim }) => {
  const classes = styles();
  const { l10n } = useLocalization();
  const dispatch = useAppDispatch();

  const events = useSelector(ivimsSelector);
  const event = events.find((ivim) => ivim.id === id);

  const onDeleteIvim = () => {
    dispatch(ivimsReducer.actions.deleteIVIM(id));
  };

  const onDisableIvim = () => {
    dispatch(ivimsReducer.actions.disableIVIM({ ids: [id], disable: true }));
  };

  const onEnableIvim = () => {
    dispatch(ivimsReducer.actions.disableIVIM({ ids: [id], disable: false }));
  };

  const mapIvimType = (type: number) => {
    const matchedType = ivimType().find((t) => t.value === type);
    if (matchedType) {
      return matchedType.label;
    }
    return '-';
  };

  if (!event) {
    return (
      <EventLayout
        type={DialogType.IVIM}
        active={false}
        header={'Unknown event'}
        disabled={false}
        scheduled={false}
      >
        Unknown event #{id} selected
      </EventLayout>
    );
  }
  // TODO fix commented stuff after IVIM reimplementation
  return (
    <EventLayout
      type={DialogType.IVIM}
      disabled={event.disabled && new Date(event.expiresAt) > new Date()}
      active={isFuture(new Date(event.ends))}
      header={l10n.getString(`forms-ivim-ser-cat-cod-enum-${event?.serviceCategoryCode}`, {
        defaultValue: event?.serviceCategoryCode,
      })}
      date={new Date(event.starts)}
      broadcastingFrequency={event.broadcastingFrequency.toString()}
      onDelete={onDeleteIvim}
      onDisable={onDisableIvim}
      onEnable={onEnableIvim}
      onUpdate={() => (onUpdateIvim ? onUpdateIvim(event.id) : null)}
      onClone={() => (onCloneIvim ? onCloneIvim(event.id) : null)}
      scheduled={isFuture(new Date(event.starts))}
    >
      <CardContent className={classes.detailStyle}>
        <Grid container spacing={1} style={{ marginTop: 10 }}>
          <Grid item xs={12} className={classes.gridVertical}>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              ID: <strong>{event.id}</strong>
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-sp-country-code')}{' '}
              <strong>{event?.providerIdentifier ?? '-'}</strong>
            </Typography>

            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-sp-country-id')}{' '}
              <strong>{event?.countryCode ?? '-'}</strong>
            </Typography>

            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-type')} <strong>{mapIvimType(event?.iviType)}</strong>
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-valid-from')} {`\n`}
              <strong>{new Date(event.createdAt).toLocaleString()}</strong>
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-valid-to')} {`\n`}
              <strong>{new Date(event.ends).toLocaleString()}</strong>
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.gridVertical}>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-reference-point')}
              {'\n'}
              <strong>
                {event.referencePoint.coordinates[1]},{event.referencePoint.coordinates[0]}
              </strong>
            </Typography>

            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              <strong>
                {l10n.getString('forms-ivim-text')} {`\n`} {event.text[0]}
              </strong>
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              <strong>
                {l10n.getString('forms-ivim-extra-text')} {`\n`} {event.text[1]}
              </strong>
            </Typography>

            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-language-text')} {`\n`}
              <strong>{event?.language ?? '-'}</strong>
            </Typography>

            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-ser-cat-cod')} {`\n`}
              <strong>{mapServiceCategoryCode(event?.serviceCategoryCode, l10n)}</strong>
            </Typography>

            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-ser-sub-cat-cod')} {`\n`}
              <strong>{mapServiceCategorySubCode(event?.serviceSubCategoryCode, l10n)}</strong>
            </Typography>

            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-pictogram-name')} {`\n`}
              <strong>
                {mapPictogramText(
                  event?.pictogramNature,
                  event?.pictogramSerialNumber,
                  event?.serviceCategoryCode,
                  event?.serviceSubCategoryCode,
                )}
              </strong>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </EventLayout>
  );
};

export default React.memo(IvimLayout);
