import { Denm } from '_store/denm/denmsTypes';
import { Map } from 'leaflet';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import DenmMarker from './markers/DenmMarker';

const Denms: FC<{ denms: Denm[]; mapRef: Map }> = ({ denms, mapRef }): ReactElement => {
  // workaround to force re-render of markers https://github.com/yuzhva/react-leaflet-markercluster/issues/149
  const [renderKey, setRenderKey] = useState(0);
  useEffect(() => {
    setRenderKey((k) => k + 1);
  }, [denms]);

  return (
    <MarkerClusterGroup maxClusterRadius={160} disableClusteringAtZoom={13} key={renderKey}>
      {denms.map((denm, key) => (
        <DenmMarker key={key} denm={denm} mapRef={mapRef} />
      ))}
    </MarkerClusterGroup>
  );
};

export default React.memo(Denms);
