import { Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useState } from 'react';
import styles from './styles';

interface FabButtonProps {
  onClick: () => void;
  buttonText: string;
}

export const FabButton = ({ onClick, buttonText }: FabButtonProps) => {
  const [hover, setHover] = useState(false);
  const classes = styles();

  return (
    <Fab
      variant={`${hover ? 'extended' : 'circular'}`}
      color="primary"
      aria-label="add"
      className={classes.fab}
      size="medium"
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span className={classes.fabText}>{hover ? buttonText : null}</span>
      <Add />
    </Fab>
  );
};
